<template>
  <b-card title="Criar Questionario">
    <b-row>
      <b-col md="9">
        <b-form-group
          label="Pergunta"
          label-for="resp"
        >
          <b-form-input
            id="resp"
            v-model="pergunta"
            type="text"
            placeholder="Quem veio primeiro, ovo ou galinha?"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Código da Pergunta"
          label-for="codd"
        >
          <b-form-input
            id="codd"
            v-model="codPergunta"
            type="number"
            placeholder="000"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="7">
            <b-form-group
              label="Resposta"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                v-model="resposta[index]"
                type="text"
                placeholder="Inclua uma resposta"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <!-- <b-col md="2">
            <b-form-group
              label="Cost"
              label-for="cost"
            >
              <b-form-input
                id="cost"
                type="number"
                placeholder="32"
              />
            </b-form-group>
          </b-col> -->

          <!-- Quantity -->
          <b-col md="2">
            <b-form-group
              label="Pontuação"
              label-for="val"
            >
              <b-form-input
                id="val"
                type="number"
                placeholder="1"
              />
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Código"
              label-for="pos"
            >
              <!-- <b-form-input
                id="pos"
                :value="index + 1"
                plaintext
              /> -->
              <b-form-input
                id="pos"
                :value="index + 1"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Excluir</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="secondary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Incluir Resposta</span>
    </b-button>
    <b-row
      class="mt-1"
    >
      <b-col cols="12 text-right">
        <b-button
          style="width: 175px"
          variant="primary"
        >
          Enviar
        </b-button>
      </b-col>
    </b-row>
    <!-- <template #code>
      {{ codeBasic }}
    </template> -->
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from './code'

export default {
  components: {
    // BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      codeBasic,
      pergunta: '',
      resposta: [],
      valor: [],
      codPergunta: '',
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      if (this.resposta.length < 5) {
        this.items.push({
          id: this.nextTodoId += this.nextTodoId,
        })

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })
      }
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
