<template>
  <div>
    <b-row>
      <b-col cols="12">
        <form-repeater-basic />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import FormRepeaterBasic from './FormRepeaterBasic.vue'

export default {
  components: {
    BRow,
    BCol,

    FormRepeaterBasic,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>
